<template>
  <div class="nav" v-if="!$route.meta.showHeader && isPc">
    <el-menu :default-active="defaultActive" router class="el-menu-demo" mode="horizontal" @select="handleSelect"
      :ellipsis="false" style="background-color: transparent;">
      <el-image style="width: 120px;position: relative;top: -6px;" class="pointer"
        src="https://qiniu.lvmifo.com/4f0d20f1be5fbaa7/096927ac9ad98f2e.png" fit="contain" @click="toIndex" />
      <div style="width: 5vw;"></div>
      <el-menu-item index="p_index" style="font-size: 16px;">首页</el-menu-item>
      <el-menu-item index="p_retrieve" style="font-size: 16px;">预约回收</el-menu-item>
      <el-menu-item index="p_new" style="font-size: 16px;">平台资讯</el-menu-item>
      <el-menu-item index="p_aboutUs" style="font-size: 16px;">关于我们</el-menu-item>
      <div class="flex-grow" />
      <!-- <div class="nav_btn flex_center sub_bg c_fff" @click="loginIn">立即登录</div> -->
      <div class="flex_y_center flex_x_right" style="width: 160px;margin-right: 60px;">
        <el-popover :width="220" popper-style="padding: 10px 0 0;">
          <template #reference>
            <el-image style="width: 36px;background: #fff;border-radius: 50%;" class="pointer"
              src="https://qiniu.lvmifo.com/1ac6bdce76d4b2e1/7479101e8a1c06c8.png" fit="contain" />
          </template>
          <template #default>
            <div class="center" style="">
              <el-image style="width: 200px;" :src="require('@/assets/wxCode.png')" fit="contain" />
            </div>
          </template>
        </el-popover>
        <div style="width: 30px;"></div>
        <el-popover :width="220" popper-style="padding: 10px 0 0;">
          <template #reference>
            <el-image style="width: 36px;background: #fff;border-radius: 50%;" class="pointer"
              src="https://qiniu.lvmifo.com/3166f25a6313bbf5/a289b629ecd0ba5d.png" fit="contain" />
          </template>
          <template #default>
            <div class="center" style="">
              <el-image style="width: 200px;" :src="require('@/assets/zfbCode.png')" fit="contain" />
            </div>
          </template>
        </el-popover>
      </div>
    </el-menu>
  </div>
  <div style="height: 60px;" v-if="!$route.meta.showFooter && isPc"></div>

  <div style="width: 100vw;" :class="isPc ? 'minHeight' : ''">
    <router-view></router-view>
  </div>
  <div style="background: #333;color: #fff;width: 100vw;" v-if="!$route.meta.showFooter && isPc">
    <div class="con_box">
      <div class="flex_between center">
        <el-image style="width: 160px;" class="pointer"
          src="https://qiniu.lvmifo.com/4f0d20f1be5fbaa7/096927ac9ad98f2e.png" fit="contain" @click="toIndex" />
        <div style="position: relative;top: 24px;">
          <div class="flex_y_center ">
            <p class="pointer" @click="toAboutUs">关于我们</p>
            <p style="width: 1px;height: 16px;background: #fff;margin: 0 20px;"></p>
            <p class="pointer" @click="telUs">加入我们</p>
            <p style="width: 1px;height: 16px;background: #fff;margin: 0 20px;"></p>
            <p class="pointer" @click="telUs">联系我们</p>
          </div>
          <p style="margin-top: 24px;" class="f12 c_gray">网站备案：沪ICP备19001740号-1</p>
        </div>

        <div class="flex_y_center flex_x_right" style="width: 160px;">
          <el-popover :width="220" popper-style="padding: 10px 0 0;">
            <template #reference>
              <el-image style="width: 36px;background: #fff;border-radius: 50%;" class="pointer"
                src="https://qiniu.lvmifo.com/1ac6bdce76d4b2e1/7479101e8a1c06c8.png" fit="contain" />
            </template>
            <template #default>
              <div class="center" style="">
                <el-image style="width: 200px;" :src="require('@/assets/wxCode.png')" fit="contain" />
              </div>
            </template>
          </el-popover>
          <div style="width: 30px;"></div>
          <el-popover :width="220" popper-style="padding: 10px 0 0;">
            <template #reference>
              <el-image style="width: 36px;background: #fff;border-radius: 50%;" class="pointer"
                src="https://qiniu.lvmifo.com/3166f25a6313bbf5/a289b629ecd0ba5d.png" fit="contain" />
            </template>
            <template #default>
              <div class="center" style="">
                <el-image style="width: 200px;" :src="require('@/assets/zfbCode.png')" fit="contain" />
              </div>
            </template>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { ElMessageBox } from 'element-plus'
import { PhoneFilled } from '@element-plus/icons-vue'
export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      pageUrl: '',
      navList: [],
      headerList: [],
      openeds: [],
      isShowMenu: true,
      isLoading: false,
      path: '',
      isPc: false
    }
  },
  computed: {
    defaultActive: function () {
      let path = this.$route.path.replace('/', '')
      if (path == 'p_newDetail') {
        path = 'p_new'
      }
      return path; //监听路由，控制菜单选择
    }
  },
  mounted() {

    this.isiPhone()
  },
  created() {
    let screenWidth = document.body.clientWidth;
    let other_recycle_code = ''
    console.log(window.location.href)

    if (sessionStorage.getItem('channelType')) {
      return false
    }

    // 判断是否来源于顺丰小程序
    if (window.location.href.indexOf('hostEnv=sfWxapp') > -1) {
      this.isPc = false
      sessionStorage.setItem('other_recycle_code', 126)
      sessionStorage.setItem('channelType', 'shunfeng')
      return false
    }

    // 判断正常来源
    // if (window.location.href.indexOf('m_index') > -1 || window.location.href.indexOf('b_index') > -1) {
    if (window.location.href.indexOf('other_recycle_code') > -1) {
      let href = window.location.href.split('?')[1]
      for (let i in href.split('&')) {
        if (href.split('&')[i].indexOf('other_recycle_code') > -1) {
          sessionStorage.setItem('other_recycle_code', href.split('&')[i].split('=')[1])
          other_recycle_code = href.split('&')[i].split('=')[1]
        }
      }
    }
    // } else {
    //   other_recycle_code = sessionStorage.getItem('other_recycle_code')
    // }

    if (screenWidth <= 768) {
      this.isPc = false
      if (other_recycle_code == 121) {
        sessionStorage.setItem('channelType', 'debang')
      } else if (other_recycle_code == 126) {
        sessionStorage.setItem('channelType', 'shunfeng')
      } else if (other_recycle_code == 127) {
        sessionStorage.setItem('channelType', 'easy')
      } else if (other_recycle_code == 128) {
        sessionStorage.setItem('channelType', 'ruizhi')
      } else if (other_recycle_code == 129) {
        sessionStorage.setItem('channelType', 'sanjin')
      } else {
        sessionStorage.setItem('channelType', 'phone')
      }
    } else {
      this.isPc = true
    }

  },
  methods: {

    telUs() {
      ElMessageBox.alert('400-887-6885', '服务热线', {
        confirmButtonText: '好的',
        type: 'success',
        icon: markRaw(PhoneFilled),
        callback: () => {

        },
      })
    },
    loginIn() {
      this.$router.push('p_login')
    },
    toAboutUs() {
      this.$router.push('p_aboutUs')
    },
    toIndex() {
      this.$router.replace('/')
    },

    isiPhone() {
      const screenHeight = window.screen.height;
      const innerHeight = window.innerHeight;
      // const bottomBarHeight = 40; // 假设底部横条的高度大约是40px
      console.log(screenHeight, innerHeight)
      if (screenHeight != innerHeight) {
        sessionStorage.setItem('isiPhoneBottom', 15)
      } else {
        sessionStorage.setItem('isiPhoneBottom', 0)
      }
    }
  }
}
</script>

<style scoped>
.minHeight {
  min-height: calc(100vh - 256px);
}

.nav {
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all ease .3s;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  background: #fff;
  height: 60px;
}

.nav:hover {
  background: #fff;
}

.nav_btn {
  height: 40px;
  border-radius: 40px;
  margin: 10px 0;
  padding: 0 25px;
  box-sizing: border-box;
  cursor: pointer;
}

.flex-grow {
  flex-grow: 1;
}

.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: transparent;
}

.el-menu--horizontal>.el-menu-item {
  margin-left: 10px;
}
</style>
